import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  Button,
  Chip,
  Grid,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import moment from "moment/moment";

import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
  TablePagination,
  ConfirmationModal,
  ExportBtn,
  StartAndEndDateFilter,
  SelectBox,
} from "../../../../component";
import { handleLoader, setToast } from "../../../../store/reducer";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import { Visibility } from "@mui/icons-material";
import Utils from "../../../../utils/utils";

const SpOrderDetails = () => {
  usePageTitle("Ticket Orders");
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [modalState, setModalState] = useState(false);
  const [recordID, setRecordID] = useState(null);
  const initialFiltersState = {
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    status: "all",
    event_id: undefined,
  };

  const [filtersState, setFiltersState] = useState(initialFiltersState);
  console.log("🚀 ~ SpOrderDetails ~ filtersState:", filtersState);

  const urlPerPage = searchParams.get("perPage");
  const urlPage = searchParams.get("page");

  // const [unRead, setUnRead] = useState(0);
  const [liveEvents, setLiveEvents] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const getData = async () => {
    try {
      setIsLoading(true);

      // Initialize an array to hold query parameters
      const queryParams = [];

      // Add query parameters conditionally based on whether they are present
      if (page) queryParams.push(`page=${page}`);
      if (rowsPerPage) queryParams.push(`per_page=${rowsPerPage}`);
      if (filtersState.start_date)
        queryParams.push(`start_date=${filtersState.start_date}`);
      if (filtersState.end_date)
        queryParams.push(`end_date=${filtersState.end_date}`);
      if (filtersState.status !== "all")
        queryParams.push(`status=${filtersState.status}`);
      if (filtersState.event_id)
        queryParams.push(`event_id=${filtersState.event_id}`);

      // Join the query parameters with '&' and append to the URL
      const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

      // Make the API call
      let { data } = await ApiManager(
        "get",
        `superAdmin/get-ticket-orders${queryString}`,
      );

      setRecords(data.tickets);
      setColumns(data.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (urlPerPage) setRowsPerPage(Number(urlPerPage));
    if (urlPage) setPage(Number(urlPage) || page);
  }, [urlPerPage, urlPage]);

  const onChangePage = (_, newPage) => {
    searchParams.set("page", newPage);
    setSearchParams(searchParams);
    setPage(newPage);
  };

  useEffect(() => {
    const getLiveEvents = async () => {
      try {
        setIsLoading(true);
        let { data } = await ApiManager("get", `superAdmin/get-live-events`);
        setLiveEvents(
          data.response.detail.map((each) => ({
            label: each.name,
            value: each.event_id,
          })),
        );
      } catch (error) {
        console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    getLiveEvents();
  }, []);

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, filtersState]);

  const onChangeRowsPerPage = (event) => {
    searchParams.set("perPage", parseInt(event.target.value, 10));
    setSearchParams(searchParams);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModal = (_id) => {
    setModalState(true);
    setRecordID(_id);
  };

  const closeModal = () => {
    setModalState(false);
    setRecordID(null);
  };

  const deleteRecord = async () => {
    try {
      dispatch(handleLoader(true));
      await ApiManager("delete", `superAdmin/blogs/${recordID}`);
      showMessage("success", "Blog Deleted successfully.");
      closeModal();
      setRecords((prev) => ({
        ...prev,
        data: prev?.data.filter((item) => item?.blog_id !== recordID),
      }));
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = [
    "Order ID",
    "Event Name",
    "Customer Name",
    "Starting amount",
    "Payment processing fee",
    "Service fee",
    "Final amount",
    "Status",
    "Purchase Date & Time",
    "Actions",
  ];

  let exportQuery = [
    filtersState.start_date && `start_date=${filtersState.start_date}`,
    filtersState.end_date && `end_date=${filtersState.end_date}`,
    filtersState.status !== "all" && `status=${filtersState.status}`,
    filtersState.event_id && `event_id=${filtersState.event_id}&`,
  ]
    .filter(Boolean)
    .join("&");
  console.log("exportQuery: ", exportQuery);
  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Tickets orders
        </Typography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <ExportBtn
              path={`reports-ticket-orders-csv?${exportQuery}`}
              data={columns}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} xs={12}>
            <StartAndEndDateFilter
              onFilterChange={(filterState) => {
                setPage(1);
                setFiltersState((prev) => ({ ...prev, ...filterState }));
              }}
              onRequestClear={() =>
                setFiltersState((prev) => ({
                  ...prev,
                  start_date: "",
                  end_date: "",
                  start_time: "",
                  end_time: "",
                }))
              }
            />
          </Grid>
          <Grid item xl={3} lg={3} md={12} xs={12}>
            <Box>
              <SelectBox
                fullWidth
                items={liveEvents}
                label="Live Events"
                name="event_id"
                isContent={liveEvents?.length}
                value={filtersState?.event_id}
                onChange={(e) =>
                  setFiltersState({ ...filtersState, event_id: e.target.value })
                }
              />
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={12} xs={12}>
            <Stack direction={"row"} width={"100%"} spacing={1}>
              <Box width={1}>
                <SelectBox
                  fullWidth
                  items={[
                    { label: "All", value: "all" },
                    { label: "Pending", value: "pending" },
                    { label: "Completed", value: "completed" },
                  ]}
                  label="Status"
                  name="status"
                  isContent
                  value={filtersState?.status}
                  onChange={(e) =>
                    setFiltersState({ ...filtersState, status: e.target.value })
                  }
                />
              </Box>
              {filtersState?.event_id && (
                <Button
                  onClick={() => {
                    setFiltersState((pre) => ({
                      ...pre,
                      status: "all",
                      event_id: undefined,
                    }));
                  }}
                  variant="contained"
                  color="error"
                >
                  Clear
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.length &&
            records?.data.map((item, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell>{item?.ticket_order_id}</StyledTableCell>
                <StyledTableCell>
                  {item?.event_detail?.name
                    ? Utils.limitText(item?.event_detail?.name, 20)
                    : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.first_name + " " + item?.last_name}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.starting_amount}{" "}
                  {item?.event_detail.currency?.currency_symbol}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.sponsor_pp_fee === "yes" ? (
                    <Chip label="Sponsored" color="success" />
                  ) : (
                    +item?.pp_fees_amount +
                    +item?.payment_method_fix_amount +
                    item?.event_detail.currency?.currency_symbol
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.sponsor_service_fee === "yes" ? (
                    <Chip label="Sponsored" color="success" />
                  ) : (
                    item?.service_fees_amount +
                    item?.event_detail.currency?.currency_symbol
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.final_amount}{" "}
                  {item?.event_detail.currency?.currency_symbol}
                </StyledTableCell>
                <StyledTableCell>
                  <Chip
                    label={item?.status}
                    color={
                      item?.status === "completed"
                        ? "success"
                        : item?.status === "pending"
                        ? "warning"
                        : "error"
                    }
                    sx={{ textTransform: "capitalize" }}
                  />
                </StyledTableCell>

                <StyledTableCell>
                  {moment(item?.created_at).format(
                    process.env.REACT_APP_DATETIME_FORMAT,
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Details" placement="top">
                      <IconButton
                        color="info"
                        onClick={() =>
                          navigate(
                            `/admin/tickets/tickets-orders/details/${item?.ticket_order_id}`,
                          )
                        }
                      >
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableWrapper>
      </Box>
      <ConfirmationModal
        callBack={deleteRecord}
        open={modalState}
        handleClose={closeModal}
      />
    </div>
  );
};
export default SpOrderDetails;
