import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  Tooltip,
  IconButton,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";

import { useNavigate, useParams } from "react-router-dom";
import {
  ConfirmationModal,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import { useDispatch, useSelector } from "react-redux";
import { handleLoader, setToast } from "../../../../store/reducer";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";
import QrCodeModal from "../event-tickets/qr-code-modal";

const TicketType = () => {
  const { id } = useParams();
  usePageTitle("Ticket Types");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [recordID, setRecordID] = useState(null);
  const [qrCodeObj, setOrCodeObj] = useState(null);
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const _isAdmin = user?.role === "super_admin" ? true : false;
  const _symbol = loggedEvent.currencySymbol;
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [page, rowsPerPage]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/ticket-types?event_id=${id}&page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const openQrModal = (item) => {
    let _obj = {
      qr_code: item?.qr_code,
    };
    let stringify = JSON.stringify(_obj);
    setOrCodeObj(stringify);
    setQrModal(true);
  };

  const closeModal = () => {
    setModalState(false);
    setRecordID(null);
  };

  const openModal = (_id) => {
    setModalState(true);
    setRecordID(_id);
  };

  const onChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteRecord = async () => {
    try {
      dispatch(handleLoader(true));
      await ApiManager("delete", `organizer/ticket-types/${recordID}`);
      showMessage("success", "Ticket type deleted successfully.");
      closeModal();
      getData();
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = [
    "ID",
    "External ID",
    "Name",
    "Price",
    "Quantity",
    "Action Type",
    "Issue Alcohol Ban",
  ];

  if (!_isAdmin) {
    thLabels.push("Manage");
  }

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Ticket Types
        </Typography>

        {!_isAdmin && (
          <Stack direction="row" m={2}>
            <Button
              onClick={() =>
                navigate(
                  `/event/${id}/management/access-control/ticket-type/create`,
                )
              }
            >
              <CreateIcon />
              Add Ticket Type
            </Button>
          </Stack>
        )}

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
        >
          {records?.data.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.ticket_type_id}</StyledTableCell>
              <StyledTableCell>{item?.external_id}</StyledTableCell>
              <StyledTableCell>{item?.name}</StyledTableCell>
              <StyledTableCell>
                {_symbol} {item?.price}
              </StyledTableCell>
              <StyledTableCell>{item?.quantities}</StyledTableCell>
              <StyledTableCell>
                {Utils.capitalize(Utils.removeUnderscore(item?.access_type))}
              </StyledTableCell>
              <StyledTableCell>
                {item?.issue_alcohol_ban ? "Yes" : "No"}
              </StyledTableCell>
              {!_isAdmin && (
                <StyledTableCell>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Edit" placement="top">
                      <IconButton
                        color="info"
                        onClick={() =>
                          navigate(
                            `/event/${id}/management/access-control/ticket-type/update/${item?.ticket_type_id}`,
                          )
                        }
                      >
                        <CreateRoundedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                      <IconButton
                        type="button"
                        color="error"
                        onClick={() => openModal(item?.ticket_type_id)}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Qr Code" placement="top" color="info">
                      <IconButton
                        type="button"
                        onClick={() => openQrModal(item)}
                      >
                        <QrCodeIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
      <QrCodeModal
        open={qrModal}
        close={() => setQrModal(false)}
        ticketObj={qrCodeObj}
        title="Add Device"
      />
      <ConfirmationModal
        callBack={deleteRecord}
        open={modalState}
        handleClose={closeModal}
      />
    </div>
  );
};

export default TicketType;
